<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item @click="goToList">活動管理</b-breadcrumb-item>
            <b-breadcrumb-item @click="goToList">活動列表</b-breadcrumb-item>
            <b-breadcrumb-item active
              >{{
                isReadOnly ? "查看活動" : isEditing ? "編輯活動" : "新增活動"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看活動" : isEditing ? "編輯活動" : "新增活動"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*活動名稱"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.event.name.$error ? false : null"
                    v-model="event.name"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.event.name.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*活動代碼"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.event.code.$error ? false : null"
                    v-model="event.code"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.event.code.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*起始時間"
                >
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <datepicker
                        placeholder="Select Date"
                        v-model="start_at.date"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :disabled="isReadOnly"
                        :disabledDates="disabledDates"
                      ></datepicker>
                      <b-form-invalid-feedback
                        :state="!v$.start_at.date.required.$error"
                      >
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>

                    <div class="col-12 col-xl-6">
                      <vue-timepicker
                        format="HH:mm"
                        v-model="start_at.time"
                        :input-class="['form-control']"
                        :disabled="isReadOnly"
                      ></vue-timepicker>
                      <b-form-invalid-feedback
                        :state="
                          !v$.start_at.time.HH.$error ||
                          !v$.start_at.time.mm.$error
                        "
                      >
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*結束時間"
                >
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <datepicker
                        placeholder="Select Date"
                        v-model="end_at.date"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :input-class="{
                          'is-invalid': v$.start_at.$invalid,
                        }"
                        :disabled="isReadOnly"
                        :disabledDates="disabledDates"
                      ></datepicker>
                      <b-form-invalid-feedback :state="!v$.end_at.date.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        :state="!v$.start_at.date.beforeEndAt.$invalid"
                      >
                        結束時間必須大於起始時間
                      </b-form-invalid-feedback>
                    </div>

                    <div class="col-12 col-xl-6">
                      <vue-timepicker
                        :input-class="[
                          'form-control',
                          {
                            'is-invalid':
                              v$.start_at.time.HH.$error ||
                              v$.start_at.time.mm.$error ||
                              v$.start_at.date.beforeEndAt.$invalid,
                          },
                        ]"
                        v-model="end_at.time"
                        :disabled="isReadOnly"
                      ></vue-timepicker>
                      <b-form-invalid-feedback
                        :state="
                          !v$.end_at.time.HH.$error || !v$.end_at.time.mm.$error
                        "
                      >
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="是否啟用"
                >
                  <b-form-checkbox
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="event.is_enabled"
                    :disabled="isReadOnly"
                  ></b-form-checkbox>
                </b-form-group>

                <div class="row">
                  <div class="col-lg-2 col-12"></div>
                  <div class="col-lg-10 col-12">
                    <router-link
                      :to="{
                        name: 'EventRedeemCodeList',
                        parms: { event_id: event.id },
                      }"
                    >
                      查看活動序號
                    </router-link>
                    <b-button v-b-modal.modal-upload variant="primary">
                      <i class="fa fa-plus"></i>上傳兌換碼
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
                >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
                >儲存</b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
    <b-modal id="modal-upload" title="上傳兌換碼">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">請上傳帳號 Excel 檔案</label>
          <b-form-file plain v-model="file"></b-form-file>
          <b-button
            class="mt-2"
            size="sm"
            variant="outline-primary"
            href="/excel/event_redeem_code_list.xlsx"
            ><i class="fa fa-file-text"></i>下載範例檔案</b-button
          >
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
        <b-button size="sm" variant="outline-primary" @click="imoprtRedeemCode"
          >上傳</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import eventApi from "../../../apis/event";
import { format, isBefore, set, subDays, getHours, getMinutes } from "date-fns";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { zh } from "vuejs-datepicker/dist/locale";

export default {
  components: { Datepicker, VueTimepicker },
  setup: () => ({ v$: useVuelidate() }),

  validations() {
    return {
      event: {
        name: { required },
        code: { required },
      },
      start_at: {
        date: {
          required,
          beforeEndAt: () => {
            const startDate = set(new Date(this.start_at.date), {
              hours: this.start_at.time.HH,
              minutes: this.start_at.time.mm,
            });

            const endDate = set(new Date(this.end_at.date), {
              hours: this.end_at.time.HH,
              minutes: this.end_at.time.mm,
            });

            return isBefore(startDate, endDate);
          },
        },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      end_at: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
    };
  },

  data() {
    return {
      zh,
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      disabledDates: { to: subDays(new Date(), 1) },
      start_at: {
        date: new Date(),
        time: {
          HH: "00",
          mm: "00",
        },
      },
      end_at: {
        date: new Date(),
        time: {
          HH: "23",
          mm: "59",
        },
      },
      event: {
        name: null,
        code: null,
        start_at: null,
        end_at: null,
        is_enabled: false,
      },
      file: null,
      showLoadingUpload: false,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),

    ...mapGetters("route", ["routeQuery"]),

    isReadOnly() {
      return this.$route.name === "EventView";
    },
  },
  mounted() {
    if (!this.$route.params.event_id) {
      this.clearEvent();
      this.isEditing = false;
    } else {
      this.fetchEvent();
      this.isEditing = true;
    }
  },
  methods: {
    async fetchEvent() {
      this.showLoading = true;
      const response = await eventApi.getEvent(
        this.organization,
        this.$route.params.event_id
      );
      const { start_at, end_at } = response.data.data;
      this.event = response.data.data;
      this.start_at = {
        date: new Date(start_at),
        time: {
          HH: String(getHours(new Date(start_at))).padStart(2, "0"),
          mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
        },
      };
      this.end_at = {
        date: new Date(end_at),
        time: {
          HH: String(getHours(new Date(end_at))).padStart(2, "0"),
          mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
        },
      };
      this.showLoading = false;
    },

    clearEvent() {
      this.event = {
        name: null,
        code: null,
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        is_enabled: true,
      };
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      this.event.start_at = format(
        set(new Date(this.start_at.date), {
          hours: Number(this.start_at.time.HH),
          minutes: Number(this.start_at.time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.event.end_at = format(
        set(new Date(this.end_at.date), {
          hours: this.end_at.time.HH,
          minutes: this.end_at.time.mm,
          seconds: 59,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );

      try {
        if (this.isEditing) {
          let response = await eventApi.updateEvent(
            this.organization,
            this.event
          );
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.goToList();
          }
        } else {
          let response = await eventApi.storeEvent(this.event);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.goToList();
          }
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    handleCancel() {
      this.goToList();
    },
    goToList() {
      this.$router.push({ name: "EventList", query: this.routeQuery });
    },
    async imoprtRedeemCode() {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", this.file);

      try {
        await eventApi.imoprtRedeemCode(
          this.organization,
          this.event,
          formData
        );
        this.$swal.fire({ title: "上傳成功", type: "success" });
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message[0]) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message[0] ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-upload");
      }
    },
  },
};
</script>
